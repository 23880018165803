import axios from "axios";

const API_URL = process.env.REACT_APP_API


async function GetAllUsers() {
    const getAllUsersQuery = axios.get(`${API_URL}/admin/get-users`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`
        }
    });
    return getAllUsersQuery;
}

/**
* @description Function to return server and users stats 
* @param {string} newRole 
* @param {string} selectedUser 
* @returns {import("axios").AxiosResponse} jsonStats
*/
async function modifyUsersRole(selectedUser, newRole) {
    try {
        const response = await axios.put(`${API_URL}/admin/set-role`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            },
            userId: selectedUser,
            role: newRole
        });
        return response.data;
    } catch (error) {
        throw new Error('Erreur lors de la mise à jour du rôle de l\'utilisateur.')
    }
    
}

export {GetAllUsers, modifyUsersRole};