import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Footer from 'components/footer';
import Headers from 'components/header';
import CssBaseline from "@mui/material/CssBaseline";
import { GetAllUsers, modifyUsersRole } from './ManageUsersService';
import { Container } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RoleEditDialog from '../Dialogue/RoleEditDialogue';
import DeleteConfirmationDialog from '../Dialogue/DeleteDialogue';

function ManageUsers() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialoDeletegOpen, setDeleteDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [newRole, setNewRole] = useState('');
  const [users, setUsers] = useState([]);
  const roles = ['owner', 'user', 'admin'];
  const [error, setError] = useState(null);

  const handleDialogOpen = (userId) => {
    setSelectedUser(userId);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleDeleteDialogOpen = (userId) => {
    setSelectedUser(userId);
    setDeleteDialogOpen(true);
    console.log("Suppression de l'utilisateur : " + userId)
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleRoleSave = async (selectedRole) => {
    // Envoyez une requête au backend pour mettre à jour le rôle de l'utilisateur
    // selectedUser est l'ID de l'utilisateur, selectedRole est le nouveau rôle
    // Gérez la mise à jour du rôle ici
    try {
      modifyUsersRole(selectedUser, selectedRole);
      window.location.reload();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteUser = async (selectedUser) => {
    // Envoyez une requête au backend pour mettre à jour le rôle de l'utilisateur
    // selectedUser est l'ID de l'utilisateur, selectedRole est le nouveau rôle
    // Gérez la mise à jour du rôle ici
    // try {
    //   modifyUsersRole(selectedUser, selectedRole);
    //   window.location.reload();
    // } catch (error) {
    //   setError(error.message);
    // }
    console.log(selectedUser)
    // window.location.reload();
  };

  useEffect(() => {
    const getAllUsers = async () => {
      try {
        const response = await GetAllUsers();; // Remplacez par l'URL de votre API
        setUsers(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des utilisateurs', error);
      }
    };

    getAllUsers(); // Appel de la fonction pour effectuer la requête au chargement initial de la page
  }, []); //


  const handleEditPassword = (userId) => {
    // Implémentez la logique pour modifier le mot de passe de l'utilisateur ici
    // Vous pouvez ouvrir un formulaire de modification du mot de passe
    console.log("Changement de mdp pour l'utilisateur : " + userId)

  };

  const handleAddUser = () => {
    // Implémentez la logique pour ajouter un nouvel utilisateur
    // Vous pouvez ouvrir un formulaire d'ajout d'utilisateur
    console.log("Ouverture panneau création compte : ")
  };


  return (
    <div>
      <Headers />
      <CssBaseline />
      <Container maxWidth="sm">
        <h1>Liste des utilisateurs</h1>
        {users.map((user) => (
          <div>
            <Card key={user.uniqueId} variant="outlined" style={{ marginBottom: '16px' }}>
              <CardContent>
                <Typography variant="h6" component="div">
                  {user.username}
                </Typography>
                <Typography color="textSecondary">
                  Email: {user.email}
                </Typography>
                <Typography color="textSecondary">
                  Rôle: {user.role}
                </Typography>
                <Typography color="textSecondary">
                  Date d'inscription : {user.registerDate}
                </Typography>
                <Typography color="textSecondary">
                  Dernière connexion: {user.lastConnectionDate}
                </Typography>
                {user.accountVerified ? (
                  <Typography color="textSecondary">
                    Vérifié : OUI
                  </Typography>
                ) : (
                  <Typography color="textSecondary">
                    Vérifié : NON
                  </Typography>
                )}

                <Button onClick={() => handleDeleteDialogOpen(user.username)} color="error">
                  Supprimer
                </Button>

                <Button onClick={() => handleEditPassword(user.uniqueId)}>
                  Modifier le mot de passe
                </Button>
                <Button onClick={() => handleDialogOpen(user.uniqueId)}>
                  Modifier le rôle
                </Button>
              </CardContent>
            </Card>
            <RoleEditDialog
              open={dialogOpen}
              onClose={handleDialogClose}
              onSave={handleRoleSave}
              roles={roles}
              selectedRole={newRole}
            />

          </div>
        ))}
            <DeleteConfirmationDialog
              open={dialoDeletegOpen} // Ouvrez la boîte de dialogue pour l'utilisateur actuel
              onClose={handleDeleteDialogClose}
              onDelete={handleDeleteUser}
              user={selectedUser} // Passez l'utilisateur actuel
            />
        <Button variant="contained" onClick={handleAddUser}>
          Ajouter un utilisateur
        </Button>
      </Container>
      <Footer />
    </div>
  );
}

export default ManageUsers;