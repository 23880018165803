import axios from "axios";

const API_URL = process.env.REACT_APP_API


async function GetAllServers() {
    const getAllServersQuery = axios.get(`${API_URL}/admin/get-servers`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`
        }
    });
    return getAllServersQuery;
}

/**
* @description Function to return username from uniqueId
* @param {string} ID The unique ID of the player
* @returns {import("axios").AxiosResponse} Username
*/
async function GetUsernameByID(ID) {
    try {
        const response = await axios.get(`${API_URL}/admin/get-username-by-id`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            },
            userId: ID
        });
        // return response;
    } catch (error) {
        throw new Error('Erreur lors de la mise à jour du rôle de l\'utilisateur.')
    }
    
}

export {GetAllServers, GetUsernameByID};