import React from "react";

import Headers from "components/header";
import Footer from "components/footer";
import { Box, Button, Container, Typography } from '@mui/material';

export default (() => {
  return (
    <>
      {/* <Theme theme={theme}> */}
        <Headers />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '77vh',
            width: '100%',
            // height: '100%'
            // bgcolor: 'lightBlue'
          }}
        >
          <Container>
            <div>
              <iframe title="Error" src="https://giphy.com/embed/H54feNXf6i4eAQubud"></iframe>
            </div>

                <Typography variant="h1">
                  404
                </Typography>
                <Typography variant="h6">
                  The page you’re looking for doesn’t exist.
                </Typography>
                <Button
                  onClick={(e) => {
                    window.location.replace('/')
                  }}
                  
                  variant="contained">Back Home
                </Button>
          </Container>
        </Box>

      {/* </Theme>; */}
      <Footer />
    </>
  )
});