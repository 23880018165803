import * as React from "react";
import theme from "theme";
import {Override, SocialMedia, RawHtml } from "@quarkly/components";
import { Container, Paper } from "@mui/material";
import { Theme } from "@quarkly/widgets";
import { GlobalQuarklyPageStyles } from "global-page-styles";
export default function Footer() {

    return (
        <>
		<Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		{/* <Container> */}
		<Paper sx={{marginTop: 'calc(10% + 60px)',
				position: 'relative',
				bottom: 0,
				width: '100%',
				backgroundColor: "black"
				}} component="footer" square>
				<Container maxWidth="xl">
			{/* <Section background="--color-dark" padding="60px 0" sm-padding="40px 0"> */}
				<SocialMedia
					discord="https://discord.gg/vxHG7VfMHN"
					twitter="https://twitter.com/hard_rp"
				>
					<Override
						slot="link"
						border-radius="50%"
						color="--grey"
						hover-color="--light"
						background="transparent"
						hover-background="transparent"
						margin="0 8px"
					/>
				</SocialMedia>
				</Container>
			{/* </Section> */}
		</Paper>
		{/* </Container> */}
		<RawHtml>
			<style place={"endOfHead"} rawKey={"63a5eacc1d363b0026b3d8b3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
		</Theme>
        </>
    )
}