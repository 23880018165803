import React, {Suspense, useEffect, useState} from "react";
import { BounceLoader } from "react-spinners";
import { userServices } from "components/protected/userServices";
import ServerForm from "./rcon/serverList";

const ProtectedRoute = () => {
    const [isUserConnected, setIsUserConnected] = useState(false);

    useEffect(() => {
        userServices.IsAuthenticatedFunction().then(() => {
            setIsUserConnected(true)
        })
    }, [isUserConnected]);

    if (isUserConnected) {
        return(
            <>
            <div className="AppRoute">
                <Suspense fallback={<BounceLoader />}>
                    <ServerForm />
                </Suspense>
            </div>
            </>
        )
    }
}

export default ProtectedRoute