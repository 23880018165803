import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormControl, FormLabel } from '@mui/material';
import Footer from 'components/footer';
import Headers from 'components/header';
import Box from '@mui/material/Box';
import { userServices } from "components/protected/userServices";
import Card from '@mui/material/Card';
import { Container } from '@mui/material';


export default function ServerForm() {

  const [serverName, setServerName] = useState('');
  const [serverIp, setServerIp] = useState('');
  const [serverPort, setServerPort] = useState('');
  const [serverPassword, setServerPassword] = useState('');
  const [user, setUser] = useState(null)

  useEffect(() => {
    const userProfile = userServices.GetUserProfile()
    userProfile.then(function (result) {
      setUser(result)
    })
  }, []);

  function handleSubmit(e) {

    e.preventDefault();

    const addServerRequest = userServices.AddRconServer(serverName, serverIp, serverPort, serverPassword, user.uniqueId, user.username)
    addServerRequest.then((response) => {
      console.log(response.data); // Handle the API response
    })
      .catch((error) => {
        console.error(error); // Handle the error
      });
  }

  return (
    <>
      <Headers />
      <Container>
        <Card>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            onSubmit={handleSubmit}
          >
            <FormControl>
              <FormLabel htmlFor="serverName">Server Name:</FormLabel>
              <TextField
                autoComplete="name"
                type="text"
                id="serverName"
                value={serverName}
                onChange={e => setServerName(e.target.value)}
              />
              <br />
              <FormLabel htmlFor="serverIp">Server IP:</FormLabel>
              <TextField
                autoComplete="ip"
                type="text"
                id="serverIp"
                value={serverIp}
                onChange={e => setServerIp(e.target.value)}
              />
              <br />
              <FormLabel htmlFor="serverPort">Server Port:</FormLabel>
              <TextField
                autoComplete="port"
                type="text"
                id="serverPort"
                value={serverPort}
                onChange={e => setServerPort(e.target.value)}
              />
              <br />
              <FormLabel htmlFor="serverPassword">Server Password:</FormLabel>
              <TextField
                autoComplete="password"
                type="password"
                id="serverPassword"
                value={serverPassword}
                onChange={e => setServerPassword(e.target.value)}
              />
              <br />
              <Button type="submit">Add Server</Button>
            </FormControl>
          </Box>
        </Card>
      </Container>
      <Footer />
    </>
  )
}
