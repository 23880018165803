import React, {Suspense, useEffect, useState} from "react";
import ManageServers from './ManageServers'
import { BounceLoader } from "react-spinners";
import { userServices } from "components/protected/userServices";

function ManagerServerRoute () {
    const [isUserAdmin, setUserAdmin] = useState(false);

    useEffect(() => {
        userServices.IsUserAdmin().then(() => {
            setUserAdmin(true)
        });
    }, [isUserAdmin]);
    if(isUserAdmin) {
        return (
            <>
                <div className="AppRoute">
                    <Suspense fallback={<BounceLoader />}>
                        <ManageServers/>
                    </Suspense>
                </div>
            </>
        )
    }
}


export default ManagerServerRoute;