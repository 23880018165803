import React, { useState, useEffect } from 'react';

import Footer from 'components/footer';
import Headers from 'components/header';
import Box from '@mui/material/Box';
import { GetSyslog } from './loggingServices';
import { Container } from '@mui/material';


export default function LogsForm() {

  const [syslogs, setSyslogs] = useState('');

  const fetchLogs = () => {
    const getSysLogsRequest = GetSyslog();
      getSysLogsRequest.then((response) => {
        setSyslogs(response);
        // Planifie la prochaine requête dans 10 secondes
        // setTimeout(fetchLogs, 10000);
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération des journaux :', error);
        // En cas d'erreur, réessayer dans 10 secondes
        setTimeout(fetchLogs, 10000);
      });
  };
  useEffect(() => {
    // Démarre la première requête au chargement de la page
    // fetchLogs();
  }, []);

  return (
    <>
      <Headers />
      <Container>
      <h1>Syslogs Viewer</h1>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <button onClick={fetchLogs}>Obtenir les journaux</button>
        <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
        
        <pre>{syslogs.data}</pre>
      </div>
      </Box>
      </Container>
      <Footer />
    </>
  )
}
