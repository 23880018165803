import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

function DeleteConfirmationDialog({ open, onClose, onDelete, user }) {
  const [randomWord, setRandomWord] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [userToDelete, setUserToDelete] = useState(user)
  // console.log(user)
  const generateRandomWord = () => {
    // Générez un mot aléatoire (vous pouvez personnaliser cette logique)
    const randomWords = ["apple", "banana", "cherry", "date", "grape"];
    const randomIndex = Math.floor(Math.random() * randomWords.length);
    setRandomWord(randomWords[randomIndex]);
  };

  const handleDelete = (event) => {
    if (inputValue === randomWord) {
      setUserToDelete(event.target.value)
      onDelete(userToDelete);
      // console.log(userToDelete)
    }
    onClose();
  };
  // console.log(userToDelete)
  // generateRandomWord()
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirmation de suppression</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Pour confirmer la suppression, veuillez entrer le mot suivant : <strong>{randomWord}</strong>
        </DialogContentText>
        <TextField
          label="Mot de confirmation"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <Button onClick={generateRandomWord}>Générer un nouveau mot</Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button color="error" onClick={handleDelete} value={user}>
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteConfirmationDialog;
