import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControlLabel, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Checkbox from "@mui/material/Checkbox";
import CssBaseline from "@mui/material/CssBaseline";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Alert from '@mui/material/Alert';
import Footer from 'components/footer';
import Headers from 'components/header';
import { userServices } from "components/protected/userServices";
import LinearProgress from '@mui/material/LinearProgress';
import zxcvbn from 'zxcvbn';
// import { UserLogin, UserRegister } from 'components/protected/userServices';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Login() {

  const [username, setUserName] = React.useState();
  const [password, setPassword] = React.useState();
  const [rePassword, setRePassword] = React.useState();
  const [email, setEmail] = React.useState();
  const [value, setValue] = React.useState(0);
  const [error, setError] = React.useState('');
  const [success, setSucess] = React.useState('');
  const [passwordStrength, setPasswordStrength] = React.useState('');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmitLogin = async e => {
    e.preventDefault();
    const userLoginRequest = userServices.UserLogin(username, password)
    userLoginRequest.then((response) => {
      // If the login is successful, save the JWT token
      localStorage.setItem('jwt', response.data.token);
      setError(null)
      setSucess('Login successful !')
      // Redirect the user to the protected page
      window.location.href = '/dashboard';
    })
      .catch((err) => {
        // If the login fails, display an error message
        setError(err.response.data.message)
        setSucess(null)
      });
  }

  const handleSubmitRegister = async e => {
    if (!email || !username) {
      setError('Please fill all inputs')
    }
    e.preventDefault();
    const userRegisterRequest = userServices.UserRegister(email, password, rePassword, username)
    userRegisterRequest.then((response) => {
      console.log(response)
      setError(null)
      setSucess("Register succesfull !\nPlease login now");
      window.location.href = '/login';
      // localStorage.setItem('jwt', response.data);
    })
      .catch((err) => {
        // If the login fails, display an error message
        setSucess(null)
        setError(err.response.data.message);
      });
  }
  const onPasswordChange = (e) => {
    setPassword(e.target.value)
    const result = zxcvbn(e.target.value);
    let password = e.target.value;
    const score = result.score;
    setPasswordStrength(score);


    setError(null);
    let caps, small, num, specialSymbol;
    if (password.length < 8) {
      setError(
        "Password should contain minimum 12 characters, with one UPPERCASE, lowercase, number and special character: @$! % * ? &"
      );
      return;
    } else {
      caps = (password.match(/[A-Z]/g) || []).length;
      small = (password.match(/[a-z]/g) || []).length;
      num = (password.match(/[0-9]/g) || []).length;
      specialSymbol = (password.match(/\W/g) || []).length;
      if (caps < 1) {
        setError("Must add one UPPERCASE letter");
        return;
      } else if (small < 1) {
        setError("Must add one lowercase letter");
        return;
      } else if (num < 1) {
        setError("Must add one number");
        return;
      } else if (specialSymbol < 1) {
        setError("Must add one special symbol: @$! % * ? &");
        return;
      }
    }
  };

  return (
    <>
      <div>
        <Headers />
        <CssBaseline />
      </div>
      <Box sx={{ width: '100%', height: '100%' }} >
        <div>
          <Box>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
              <Tab icon={<LockOpenIcon />} label="Login" {...a11yProps(0)} />
              <Tab icon={<LockOutlinedIcon />} label="Sign Up" {...a11yProps(1)} />
            </Tabs>
          </Box>
        </div>

        <TabPanel value={value} index={0}>
          <Box
            sx={{
              // marginTop: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOpenIcon />
            </Avatar>
            <Typography sx={{ display: { xs: 'none', md: 'flex' } }} component="h1" variant="h5">Please enter your credentials</Typography>
            <div>
              <Box
                component="form"
                onSubmit={handleSubmitLogin}
                noValidate
                sx={{ mt: 1, marginBottom: 10 }}
              >
                {
                  success && <Alert variant="filled" severity="success">
                    {success}
                  </Alert>
                }
                {
                  error && <Alert variant="filled" severity="error">
                    {error}
                  </Alert>
                }
                <TextField id="username" label="Username" variant="outlined" type="text" required margin='normal' fullWidth autoComplete="email" autoFocus onChange={e => setUserName(e.target.value)} />
                <TextField id="password" label="Password" variant="outlined" type="password" required margin='normal' fullWidth autoComplete="current-password" onChange={e => setPassword(e.target.value)} />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Button variant="outlined" endIcon={<SendIcon />} size="large" type='submit' fullWidth sx={{ mt: 3, mb: 2 }}>
                  Login
                </Button>
              </Box>
            </div>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box
            sx={{
              // marginTop: 7,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography sx={{ display: { xs: 'none', md: 'flex' } }} component="h1" variant="h5">Sign up</Typography>
            <div>
              <Box
                component="form"
                onSubmit={handleSubmitRegister}
                noValidate
                sx={{ mt: 1, marginBottom: 10 }}
              >
                {
                  success && <Alert variant="filled" severity="success">
                    {success}
                  </Alert>
                }
                {
                  error && <Alert variant="filled" severity="error">
                    {error}
                  </Alert>
                }
                <TextField id="username" label="Username" variant="outlined" type="name" required margin='normal' fullWidth autoComplete="username" autoFocus onChange={e => setUserName(e.target.value)}
                />
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  type="email"
                  required
                  margin='normal'
                  fullWidth
                  autoComplete="email"
                  autoFocus
                  onChange={e => setEmail(e.target.value)}
                />
                <TextField
                  id="password"
                  label="Password"
                  variant="outlined"
                  type="password"
                  required
                  margin='normal'
                  fullWidth
                  autoComplete="current-password"
                  // onChange={e => setPassword(e.target.value)}
                  onChange={onPasswordChange}
                />
                <TextField
                  id="rePassword"
                  label="Confirm password"
                  variant="outlined"
                  type="password"
                  required
                  margin='normal'
                  fullWidth
                  autoComplete="current-password"
                  onChange={e => setRePassword(e.target.value)}
                />
                <br></br>
                <LinearProgress
                  variant="determinate"
                  value={passwordStrength * 25} // zxcvbn score * 25 pour obtenir un pourcentage
                />
                {passwordStrength >= 2 ? (
                  <Button variant="outlined" endIcon={<SendIcon />} size="medium" type='submit' fullWidth sx={{ mt: 3, mb: 2 }}>
                    Register
                  </Button>
                ) : (
                  <>
                    <Button variant="outlined" endIcon={<SendIcon />} size="medium" type='submit' fullWidth sx={{ mt: 3, mb: 2 }} disabled>
                      Register
                    </Button>
                    <Typography>Le mot de passe est trop faible !</Typography>
                  </>
                )}
              </Box>
            </div>
          </Box>
        </TabPanel>
      </Box>
      <Footer />
    </>
  );
};
