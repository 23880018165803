import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Footer from 'components/footer';
import Headers from 'components/header';
import CssBaseline from "@mui/material/CssBaseline";
import { Container } from '@mui/material';

import { GetAllServers } from './ManageServersService';

function ManageServers() {

  const [servers, setServers] = useState([]);
  const [users, setUsers] = useState([]);


  useEffect(() => {
    const getAllServers = async () => {
      try {
        const response = await GetAllServers();; // Remplacez par l'URL de votre API
        setServers(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des utilisateurs', error);
      }
    };

    getAllServers(); // Appel de la fonction pour effectuer la requête au chargement initial de la page
  }, []); //

    if (servers === null) {
      return <div>Chargement en cours...</div>;
    }

  return (
    <div>
      <Headers />
      <CssBaseline />
      <Container maxWidth="sm">
        <h1>Liste des serveurs</h1>
        {Object.keys(servers).map(serverId => {
          const server = servers[serverId];
          // const owner = server.owner;
          const serverData = server.data;
          const serverName = serverData.serverName;
          const serverIp = serverData.serverIp;
          const serverPort = serverData.serverPort;
          const username = serverData.username;
        return (
            <Card key={serverId} style={{ margin: '16px' }}>
              <CardContent>
                <h2>ID : {serverId}</h2>
                <Typography>Propriétaire : {username}</Typography>
                <Typography>Nom du serveur : {serverName}</Typography>
                <Typography>IP du serveur : {serverIp}</Typography>
                <Typography>Port du serveur : {serverPort}</Typography>
              </CardContent>
            </Card>
        )})}
      </Container>
      <Footer />
    </div>
  );
}
export default ManageServers;