import React, {Suspense, useEffect, useState} from "react";
import { BounceLoader } from "react-spinners";
import { userServices } from "components/protected/userServices";
import LogsForm from "./logging";


import axios from "axios";

const API_URL = process.env.REACT_APP_API


/**
* @description Function to return server and users stats 
* @returns {JSON} jsonStats
*/
async function GetSyslog() {
    const getSyslog = axios.get(`${API_URL}/logs/syslog`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`
        }
    });
    return getSyslog;
}

const Logging = () => {
    const [isUserConnected, setIsUserConnected] = useState(false);

    useEffect(() => {
        userServices.IsAuthenticatedFunction().then(() => {
            setIsUserConnected(true)
        })
    }, [isUserConnected]);

    if (isUserConnected) {
        return(
            <>
            <div className="AppRoute">
                <Suspense fallback={<BounceLoader />}>
                    <LogsForm />
                </Suspense>
            </div>
            </>
        )
    }
}

export default Logging
export {GetSyslog};