import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

function RoleEditDialog({ open, onClose, onSave, roles, selectedRole }) {
  const [role, setRole] = useState(selectedRole);

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleSave = () => {
    onSave(role);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Modifier le rôle</DialogTitle>
      <DialogContent>
        <DialogContentText>Sélectionnez le nouveau rôle :</DialogContentText>
        <Select value={role} onChange={handleRoleChange} label="Rôle" autoWidth>
          {roles.map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Annuler
        </Button>
        <Button onClick={handleSave} color="primary">
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RoleEditDialog;
