import axios from "axios";

const API_URL = process.env.REACT_APP_API


/**
* @description Function to return server and users stats 
* @returns {JSON} jsonStats
*/
async function GetStats() {
    const getStatsQuery = axios.get(`${API_URL}/auth/stats`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`
        }
    });
    return getStatsQuery;
}

  const FetchHostStats = async () => {
    try {
        const responseCpu = await axios.get(`${API_URL}/stats/cpu-usage`);
        const responseRam = await axios.get(`${API_URL}/stats/ram-usage`);
        const responseDisk = await axios.get(`${API_URL}/stats/disk-usage`);
        const responseFinal = {responseCpu, responseRam, responseDisk}
      return responseFinal;
    } catch (error) {
      console.error('Error fetching Host stats:', error);
    }
  };

export {GetStats, FetchHostStats}