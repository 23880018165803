import * as React from "react";
import { Image, Text, Section, LinkBox } from "@quarkly/widgets";
import Box from '@mui/material/Box';
import Box2 from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { AlertTitle, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import Footer from 'components/footer';
import Headers from 'components/header';
import CssBaseline from "@mui/material/CssBaseline";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
  });

export default (() => {

	const [open, setOpen] = React.useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	  };
	
	  const handleClose = () => {
		setOpen(false);
	  };

	return (
		<>
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			aria-describedby="alert-dialog-slide-description"
      	>
			<DialogTitle>{"HardLife RP"}</DialogTitle>
			<DialogContent>
			<DialogContentText id="alert-dialog-slide-description">
				Souhaitez-vous vous connecter au serveur ? 
			</DialogContentText>
			</DialogContent>
			<DialogActions>
			<Button onClick={handleClose}>Non</Button>
			<Button onClick={handleClose} href="https://cfx.re/join/639pd8">Oui</Button>
			</DialogActions>
      	</Dialog>
		
		<Headers />
		<CssBaseline />
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Typography
					variant="h1"
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Serveurs   
					<SportsEsportsIcon fontSize="large"/>
				</Typography>
				<Typography font="--lead" color="--darkL2" max-width="600px">
					Ci-dessous se trouve les différents serveurs de jeux auquel AO-Hostings prend part.
				</Typography>
			</Box>
			<Box2 sx={{ width: '100%' }}>
				<Alert severity="info">
					<AlertTitle>Informations</AlertTitle>
					La page est actuellement en <strong>travaux</strong>.
					Merci de repasser prochainement pour suivre l'avancée.
				</Alert>
			</Box2>
			<br />
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
				onClick={handleClickOpen}
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/63a5eacc1d363b0026b3d8b5/images/WaintingScreen2.png?v=2022-12-23T18:10:20.657Z"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit=""
						lg-max-height="392px"
						sm-max-height="21px"
						
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Typography margin="0px 0px 16px 0px" font="--lead"  opacity="0.5">
						2021
					</Typography>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Typography margin="0px 0px 8px 0px" font="--headline3" >
							Serveur FiveM
						</Typography>
						<Typography margin="0px 0px 0px 0px" font="--lead" >
							Un serveur roleplay bien avancé avec tant de scripts inédits !
						</Typography>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://apps.modpacks.ch/modpacks/art/97/DW20%201024%20x%201024.png"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit=""
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Typography margin="0px 0px 16px 0px" font="--lead"  opacity="0.5">
						2022
					</Typography>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Typography margin="0px 0px 8px 0px" font="--headline3" >
							Serveur Minecraft
						</Typography>
						<Typography margin="0px 0px 0px 0px" font="--lead">
							Sous Direwolf20 1.19 - Version 1.0.1 | Serveur Whitelisté survie
						</Typography>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://external-preview.redd.it/6tjrDUJbt5qxaBMxBRWwnvmvRn_qKGZonG2N0pVj4IA.jpg?auto=webp&s=101ce16e6736d6822e422af788109136fad78811"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit=""
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Typography margin="0px 0px 16px 0px" font="--lead" opacity="0.5">
						2022
					</Typography>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Typography>
							Garry's Mod
						</Typography>
						<Typography margin="0px 0px 0px 0px" font="--lead" >
							Un serveur Prophunt avec des taunts customisés français (CETTE BELLE FRANCE)
						</Typography>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Footer />
	</>
	);
});