import React, {Suspense, useEffect, useState} from "react";
import ServerDashboard from "./Dashboard";
import { BounceLoader } from "react-spinners";
import { userServices } from "components/protected/userServices";

function DashboardRoute () {
    const [isUserConnected, setIsUserConnected] = useState(false);
    // userServices();
    useEffect(() => {
        userServices.IsAuthenticatedFunction().then(() => {
            setIsUserConnected(true)
        })
    }, [isUserConnected]);


    if (isUserConnected) {
        return(
            <>
            <div className="AppRoute">
                <Suspense fallback={<BounceLoader />}>
                    <ServerDashboard />
                </Suspense>
            </div>
            </>
        )
    }
    
}

export default DashboardRoute