import axios from "axios";
import { Component } from "react";
const API_URL = process.env.REACT_APP_API
// console.log(API_URL)

/**
 * @param {string} role USER | ADMIN
 * @param {string} email EMAIL
 * @param {string} username USERNAME
 * @param {boolean} locked Locked account
 * @param {string} uuid UUID of the user
 * @description User profile class
 */
class UserProfile {
    role
    email
    username
    locked
    uuid
}


class _userServices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: 'testuserServicesProps'
        }
        this.userStatus = this.GetJWTToken()
        /**
         * @param {string} role USER | ADMIN
         * @param {string} email EMAIL
         * @param {string} username USERNAME
         * @param {boolean} locked Locked account
         * @param {string} uuid UUID of the user
         * @description User profile class
         */
        this.UserProfile = UserProfile
    }

    /**
     * 
     * @returns {Boolean}
     */
    async GetJWTToken() {
        if (localStorage.getItem('jwt')) {
            // console.log('true')
            return true
        } else {
            // console.log('false')
            return false
        }
    }

    /**
     * 
     * @returns {AxiosResponse}
     */
    async IsAuthenticatedFunction() {
        try {
            var result = await axios.get(`${API_URL}/auth/protected`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            return result
        } catch (error) {
            window.location.replace('/login')
            alert('You must been logged in!')
            return error
        }
    }
     /**
     * 
     * @returns {AxiosResponse}
     */
     async IsUserAdmin() {
        try {
            var result = await axios.get(`${API_URL}/auth/is-admin`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            return result
        } catch (error) {
            console.log(error)
            window.location.replace('/dashboard')
            alert('You must been administrator !')
            return error
        }
    }

    /**
     * 
     */
    async LogoutUser() {
        try {
            axios.delete(`${API_URL}/auth/logout`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('jwt')}`
                }
            }).then(response => {
                if (response.status === 204) {
                    localStorage.removeItem('jwt');
                    this.userStatus = false;
                    window.location.replace('/');
                } else {
                    alert("Error please contact Webmaster");
                }
            })
        } catch (error) {
            console.error(error)
        }
    }
    /**
     * 
     * @returns {UserProfile}
     */
    async GetUserProfile() {
        try {
            const result = await axios.get(`${API_URL}/auth/profile`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            UserProfile = result.data;
            this.UserProfile = UserProfile;
            return this.UserProfile
        } catch (error) {
            // console.error('Not logged ?')
        }
        
    }
    /**
     * @param {string} username The username
     * @param {string} password The password
     * @returns {loginQuery}
     */
    async UserLogin(username, password) {
        const loginQuery = axios.post(`${API_URL}/auth/login`, {
            username,
            password
        }, {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        })
        loginQuery.then(() => {
            this.userStatus = true;
        })
        return loginQuery
    }

    /**
     * @param {string} email The username
     * @param {string} password The password
     * @param {string} rePassword The rePassword
     * @param {string} username The username
     * @description Register a new account
     * @returns {AxiosResponse} registerQuery
     */
    async UserRegister(email, password, rePassword, username) {
        const registerQuery = axios.post(`${API_URL}/auth/register`, {
            email,
            password,
            rePassword,
            username
        }, {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        })
        return registerQuery
    }

    /**
     * @param {string} serverName Name
     * @param {string} serverIp IP
     * @param {string} serverPort rcon port
     * @param {string} serverPassword rcon password
     * @param {string} owner The owner's uuid
     * @description Register a new server
     * @returns {AxiosResponse} registerQuery
     */
    async AddRconServer(serverName, serverIp, serverPort, serverPassword, owner, username) {
        const addRCONServerQuery = axios.post(`${API_URL}/api/add-server`, {
            serverName,
            serverIp,
            serverPort,
            serverPassword,
            owner,
            username // need for admin ..
            // ...other form data
        })
        return addRCONServerQuery
    }
}





const userServices = new _userServices();
export { userServices }