import * as React from "react";
import { Helmet } from "react-helmet";
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import TerminalIcon from '@mui/icons-material/Terminal';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import { Box } from "@mui/material";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import HomeIcon from '@mui/icons-material/Home';
import GridViewIcon from '@mui/icons-material/GridView';
import Typography from "@mui/material/Typography";
import { userServices } from "components/protected/userServices";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Comment } from "@mui/icons-material";
import { ThemeTogglerTest } from "App";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import StorageIcon from '@mui/icons-material/Storage';
/**
 * 
 * @param {number} param 
 */
function renderIcon(param) {
	switch (param) {
		case 0:
			return <HomeIcon />
		case 1:
			return <GridViewIcon />
		case 2:
			return <TerminalIcon />
		case 3:
			return <Settings />
		case 4:
			return <Comment />
		case 5:
			return <Logout />
		default:
			return <GridViewIcon />
	}
}
/**
 * 
 * @param {number} param 
 */
function routeToPage(param) {
	switch (param) {
		case 1: // Dashboard
			window.location.replace('/dashboard')
			break;
		case 2: // RCON
			window.location.replace('/api/rcon')
			break;
		case 3: // Settings
			window.location.replace('/settings')
			break;
		case 4: // Syslogs
			window.location.replace('/logs')
			break;
		case 5: // Logout
			userServices.LogoutUser()
			window.location.replace('/')
			break;
		default: // Index
			window.location.replace('/')
			break;
	}
}

export default function Headers() {
	const [openList, setOpenList] = React.useState(false);

	const handleClick = () => {
		setOpenList(!openList);
	};
	const [user, setUser] = React.useState(null)
	const [asToken, setAsToken] = React.useState(false)
	React.useEffect(() => {
		const tokenRequest = userServices.userStatus;
		tokenRequest.then((e) => {
			// console.log(e)
			setAsToken(e)
			if (e) {
				const userProfile = userServices.GetUserProfile()
				userProfile.then(function (result) {
					setUser(result)
				})
			}
		})
		// 
	}, [asToken]);

	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event &&
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};
	const list = (anchor) => (

		<Box
			sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
			role="presentation"
			// onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<List>
				<ListItem disablePadding>
					<ListItemButton>
						<ListItemIcon>
							<AccountCircle />
						</ListItemIcon>
						{user ? (<ListItemText primary={userServices.UserProfile.username} />) : (<ListItemText>Non connecté</ListItemText>)}
					</ListItemButton>
				</ListItem>
				<Divider />
			</List>
			{user && user.role === 'admin' ? (
				<List>
					{/* <ListItem disablePadding> */}
						{/* <ListItemButton onClick={() => { window.location.replace('/admin') }}> */}
						<ListItemButton onClick={handleClick}>
							<ListItemIcon>
								<AdminPanelSettingsIcon />
							</ListItemIcon>
							<ListItemText sx={{ color: 'orange' }}>Administration</ListItemText>
							{openList ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
						<Collapse in={openList} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								<ListItemButton sx={{ pl: 4 }} onClick={() => {
										window.location.replace('/admin/users')
									}}>
									<ListItemIcon>
									<PeopleAltIcon />
									</ListItemIcon>
									<ListItemText primary="Utilisateurs"  />
								</ListItemButton>
								<ListItemButton sx={{ pl: 4 }}>
									<ListItemIcon>
									<StorageIcon />
									</ListItemIcon>
									<ListItemText primary="Serveurs" onClick={() => {
										window.location.replace('/admin/servers')
									}}/>
								</ListItemButton>
							</List>
						</Collapse>
					{/* </ListItem> */}
					<Divider />
				</List>
			) : ('')}
			{user ? (
				<List>
					{['Accueil', 'Dashboard', 'RCON', 'Paramètres', 'Syslog', 'Déconnexion'].map((text, index) => (
						<ListItem key={text} disablePadding>
							<ListItemButton onClick={() => {
								routeToPage(index)
							}}>
								<ListItemIcon>
									{renderIcon(index)}
								</ListItemIcon>
								<ListItemText primary={text} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			) : (
				<List>
					{['Accueil', 'Connexion'].map((text, index) => (
						<ListItem key={text} disablePadding>
							<ListItemButton onClick={() => {
								index % 2 === 0 ? window.location.replace('/') : window.location.replace('/login')
							}}>
								<ListItemIcon>
									{index % 2 === 0 ? <HomeIcon /> : <PersonAdd />}

								</ListItemIcon>
								<ListItemText primary={text} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			)}
			<Divider />
		</Box>
	);
	return (
		<>
			<CssBaseline />
			<Helmet>
				<title>
					AO-Hostings
				</title>
				<link rel={"shortcut icon"} href={"../img/favicon.png"} type={"image/x-icon"} />
			</Helmet>
			<AppBar position="static">
				<Container maxWidth="xl">
					<Toolbar disableGutters>
						<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
							<IconButton
								size="large"
								edge="start"
								color="inherit"
								aria-label="menu"
								onClick={toggleDrawer('left', true)}
								sx={{ flexGrow: 1, display: { xs: 'flex' } }}
							>
								<MenuIcon />
							</IconButton>
						</Box>
						<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}>
							<IconButton
								size="large"
								edge="start"
								color="inherit"
								aria-label="menu"
								onClick={toggleDrawer('left', true)}
								sx={{ flexGrow: 1, display: { xs: 'flex' } }}
							>
								<MenuIcon />
							</IconButton>
						</Box>
						<Typography
							variant="h6"
							noWrap
							component="a"
							href="/"
							sx={{
								mr: 2,
								display: { xs: 'none', md: 'flex' },
								fontFamily: 'monospace',
								fontWeight: 700,
								letterSpacing: '.3rem',
								color: 'inherit',
								textDecoration: 'none',
							}}
						>
							AO-HOSTINGS
						</Typography>
						<Typography>
						</Typography>
						<ThemeTogglerTest />
						<Typography
							variant="h5"
							noWrap
							component="a"
							href=""
							sx={{
								mr: 2,
								display: { xs: 'flex', md: 'none' },
								flexGrow: 1,
								fontFamily: 'monospace',
								fontWeight: 700,
								letterSpacing: '.3rem',
								color: 'inherit',
								textDecoration: 'none',
							}}
						>
							AO-HOSTINGS
						</Typography>
					</Toolbar>
				</Container>
			</AppBar>
			<div>
				<React.Fragment key='left'>
					<SwipeableDrawer
						variant="persistent"
						anchor={'left'}
						open={state['left']}
						onClose={toggleDrawer('left', false)}
						onOpen={toggleDrawer('left', true)}
					>
						{list('left')}
					</SwipeableDrawer>
				</React.Fragment>
			</div>
		</>
	)
}