import React from "react";
import Index from "./pages/index";
import Page404 from "./pages/page404";
import { BrowserRouter, Switch, Route, HashRouter } from "react-router-dom";
import DashboardRoute from "./components/Dashboard/dashboardRoute";
import Login from "./components/Login/Login";
import About from "./components/About/About"
import Logging from './components/protected/logging/loggingServices'
import ProtectedRoute from "components/protected/protectedRoute";
import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { IconButton } from "@mui/material";
import ManagerRoute from "components/Admin/Users/ManageUsersRoute";
import ManagerServerRoute from "components/Admin/Servers/ManageServersRoute";
const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

function ThemeTogglerTest() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  return (
    <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
      {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
  )
}

export default function App() {

  const [mode, setMode] = React.useState('light');
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
        localStorage.setItem('theme', mode)
        ;
      },
    }),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: '#3f51b5',
          },
          secondary: {
            main: '#f50057',
          },
        },
      }),
    [mode],
  );
  React.useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setMode(savedTheme);
    }
  }, []);
  React.useEffect(() => {
    localStorage.setItem('theme', mode);
  }, [mode]);
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
        {/* <Router> */}
          <Switch>
            <Route exact path='/' component={Index} />
            <Route path='/index' component={Index} />
            <Route path='/dashboard' component={DashboardRoute} />
            <Route path='/api/rcon' component={ProtectedRoute} />
            <Route path='/admin/users' component={ManagerRoute} />
            <Route path='/admin/servers' component={ManagerServerRoute}/>
            <Route path='/login' component={Login} />
            <Route path='/about' component={About} />
            <Route path='/logs' component={Logging} />
            <Route component={Page404} />
          </Switch>
          {/* </Router> */}
        </BrowserRouter>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
};
export { ThemeTogglerTest }
// export default App;
