import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
  Button,
  Box
} from '@material-ui/core';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Container } from '@mui/material';
import Footer from 'components/footer';
import Headers from 'components/header';
import { GetStats, FetchHostStats } from './dashboardServices'
import RefreshIcon from '@mui/icons-material/Refresh';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import CssBaseline from "@mui/material/CssBaseline";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    // color: theme.palette.text.secondary
  }
}));

export default function ServerDashboard() {
  const [open, setOpen] = React.useState(false); // BACKDROP
  const [users, setUsers] = React.useState();
  const [servers, setServers] = React.useState();
  const [timeState, setTimeState] = React.useState(new Date().toLocaleString());
  const [cpuUsage, setCpuUsage] = React.useState(0);
  const [usedMemory, setUsedMemory] = React.useState(0);
  const [usedSpace, setUsedSpace] = React.useState(0);

  const fetchData = async () => {
    try {
      const response = FetchHostStats();
      response.then((response) => {
        setUsedSpace(response.responseDisk.data)
        setUsedMemory(response.responseRam.data)
        setCpuUsage(response.responseCpu.data.cpuUsage)
      })
        .catch((err) => {
          console.error(err)
        })
    } catch (error) {
      console.error('Erreur lors de la récupération des données :', error);
    }
  }
  React.useEffect(() => {
    (async () => {
      const getStatsRequest = GetStats();
      setOpen(true)
      getStatsRequest.then((response) => {
        setOpen(false)
        setUsers(response.data.usersCount)
        setServers(response.data.serversCount)
      })
        .catch((err) => {
          console.error(err)
        })
    })();
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 30000);
    const intervalClock = setInterval(() => {
      setTimeState(new Date().toLocaleString());
    }, 1000);
    return () => 
    {
      clearInterval(interval);
      clearInterval(intervalClock);
    }
    

  }, []);
  const getColor = (value) => {
    if (value >= 85) {
      return 'error';
    } else if (value >= 30 & value < 50) {
      return 'success';
    } else if (value >= 50) {
      return 'warning';
    } else {
      return 'primary';
    }
  }
  const classes = useStyles();

  // Define the data for the table
  const rows = [
    { server: 'ao-hostings:25595', game: 'Minecraft' },
    { server: 'ao-hostings:30120', game: 'FiveM Server' },
    { server: 'ao-hostings:27015', game: 'Garry\'s Mod ' }
  ];
  // const diskPercentage = ((usedSpace.totalSize - usedSpace.freeSize) / 100000000) * 100;
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Headers />
      <CssBaseline />
      <Container maxWidth="sm">
        <br></br>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="h6">Overview</Typography>
              <Typography variant='h5'>{timeState}</Typography>
              <Typography variant="subtitle1">
                Welcome to the dashboard!
              </Typography>
              <Box mt={2}>
                <Button
                  color='primary'
                  variant="outlined"
                  startIcon={<RefreshIcon />}
                  onClick={() => {
                    GetStats()
                  }
                    // GetStats()
                  }
                >
                  Refresh
                </Button>
              </Box>
            </Paper>
          </Grid>
          <br></br>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="h6">Server Stats</Typography>
              <br></br>
              <Typography>CPU Usage: {Math.ceil(cpuUsage)}%</Typography>
              {/* <progress value={cpuUsage} max={100}></progress> */}
              <LinearProgress variant="determinate"  color={getColor(cpuUsage)} value={cpuUsage} />
              <Typography>Used Memory: {100 - Math.ceil(usedMemory.freememPercentage * 100)} % ( {(usedMemory.usageMem / 1000).toFixed(1)} / {Math.ceil(usedMemory.totalMem / 1000)} Go )</Typography>
              {/* <progress value={usedMemory.usageMem} max={usedMemory.totalMem}></progress> */}
              <LinearProgress variant="determinate"  color={getColor(100 - Math.ceil(usedMemory.freememPercentage * 100))} value={100 - Math.ceil(usedMemory.freememPercentage * 100)} />
              <Typography>Used Disk Space ( / ): {Math.ceil(((usedSpace.totalSize - usedSpace.freeSize) / usedSpace.totalSize) * 100)}%</Typography>
              {/* <progress value={(usedSpace.totalSize - usedSpace.freeSize) / 100000000 } max={Math.ceil(usedSpace.totalSize / 100000000)}></progress> */}
              <LinearProgress variant="determinate"  color={getColor(((usedSpace.totalSize - usedSpace.freeSize) / usedSpace.totalSize) * 100)} value={((usedSpace.totalSize - usedSpace.freeSize) / usedSpace.totalSize) * 100} />
              <Box mt={2}>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Typography variant="h6">Recent Activity</Typography>
              <Box mt={2} display="flex" alignItems="center">
                <Typography variant="subtitle1">
                  No recent activity to display.
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Typography variant="h6">Resources</Typography>
              <Box mt={2} display="flex" alignItems="center">
                <Typography variant="subtitle1">
                  You have no resources.
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Typography variant="h6">Servers</Typography>
              <Box mt={2} display="flex" alignItems="center">
                <Typography variant="subtitle1">
                  {servers}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Typography variant="h6">Users</Typography>
              <Box mt={2} display="flex" alignItems="center">
                <Typography variant="subtitle1">
                  {users}
                </Typography>
                <Box ml={2}>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <br></br>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="server table">
            <TableHead>
              <TableRow>
                <TableCell>Server</TableCell>
                <TableCell align="right">Game</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.server}>
                  <TableCell component="th" scope="row">
                    {row.server}
                  </TableCell>
                  <TableCell align="right">{row.game}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Footer />
    </>
  );
}