import React from 'react';

export default function About() {
    return (
      <div>
        <h1>About</h1>
        <p>Welcome to the about page! This is a place where you can learn more about the website and its creators.</p>
        <h2>Our Story</h2>
        <p>We started this website with the goal of creating a helpful and informative resource for people interested in technology and programming. Our team is made up of passionate developers who love to share their knowledge and experience with others.</p>
        <h2>Our Mission</h2>
        <p>Our mission is to inspire and educate people about the power of technology and programming. We believe that with the right knowledge and resources, anyone can learn to code and make a positive impact in the world.</p>
        <h2>Contact Us</h2>
        <p>If you have any questions or comments, please don't hesitate to reach out to us. You can find our contact information on the contact page.</p>
      </div>
    );
  }
  
